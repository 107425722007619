
import { mapState, mapMutations, mapActions } from 'vuex'

import { CustomButton } from '@/components';
import LocationPhoneInfo from '@pleinair/bigcolony-vue/components/locations/search/elements/PhoneInfo.vue'
import LocationAddressInfo from '@pleinair/bigcolony-vue/components/locations/search/elements/AddressInfo.vue'
import LocationOperationInfo from '@pleinair/bigcolony-vue/components/locations/search/elements/OperationInfo.vue'

export default {
  name: 'CustomLocationCard',
  components: {
    CustomButton,
    LocationPhoneInfo,
    LocationAddressInfo,
    LocationOperationInfo,
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    showHandoffModeSelector: Boolean,
    showChip: Boolean,
    config: {
      type: Object,
      default: () => {
        return {
          separator: '•',
          showServicesOnCard: false,
          showServicesOnOperation: true,
          locationPageAction: 'View Restaurant Details',
          locationPageIcon: '',
        }
      }
    },
  },
  computed: {
    ...mapState([ 'user', 'appInfo', 'cart' ]),
    ...mapState({
      favLocs: state => state.members.favLocs,
      handoffMode: state => state.order.handoffMode,
      loading: state => state.loading,
    }),
    appHandoffModes() {
      return this.appInfo.order['handoff_modes']
    },
    locationHandoffModes() {
      if (this.data) {
        return this.$utils.locationHandoffModes(this.data, this.appHandoffModes)
      }
      return []
    },
    locationDisabled() {
      return !this.data.services[this.handoffMode]
    },
    services() {
      if (this.config && this.config.showServicesOnCard && this.data && this.data.services) {
        const services = []
        if (this.data.services.delivery) {
          services.push({
            name: 'Delivery',
            iconName: 'delivery-icon',
            iconClasses: 'delivery-icon',
          })
        }
        if (this.data.services.pickup) {
          services.push({
            name: 'Pickup',
            iconName: 'pickup-icon',
            iconClasses: 'pickup-icon',
          })
        }
        if (this.data.services.curbside) {
          services.push({
            name: 'Curbside',
            iconName: 'curbside-icon',
            iconClasses: 'curbside-icon',
          })
        }
        if (this.data.services.drivethru) {
          services.push({
            name: 'Drive Thru',
            iconName: 'curbside-icon',
            iconClasses: 'curbside-icon',
          })
        }
        return services
      }

      return []
    },
  },
  methods: {
    ...mapMutations([ 'setLocation', 'setLoading', 'setMenu' ]),
    ...mapMutations({
      setShowLocationPopUp: 'app/setShowLocationPopUp',
    }),
    ...mapActions([ 'sendRequest', 'trackEvent', 'changeLocation', 'abandonCart', 'transferCart' ]),
    ...mapActions({
      saveFavLoc: 'members/saveFavLoc',
      deleteFavLoc: 'members/deleteFavLoc',
    }),
    async favorite() {
      const favLocFound = this.favLocs.find(l => l.id === this.data.id)
      if (favLocFound){
        await this.deleteFavLoc(this.data.menu_id)
      } else {
        const response = await this.saveFavLoc(this.data.menu_id)
        if (response) {
          this.trackEvent({
            name: 'location_favorited',
            data: this.data,
            windowObject: window
          })
        }
      }
    },
    handleSelectLocation() {
      const location = this.data;
      // Transfer cart when changing locations
      if (location.services?.transfercart && this.cart?.menu_id !== location.menu_id) {
        this.transferCart(location.menu_id)
      } else {
        this.abandonCart()
      }
      
      if(location) {
        this.trackEvent({
          name: 'select_location',
          data: location,
          windowObject: window
        })
      }
      
      this.setLocation(location)
      this.setMenu(null)
    },
    hideCart() {
      document.body.style.overflow = 'auto';
      this.setShowCart(false);
    },
  },
}
