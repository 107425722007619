
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'LocationPopUp',
  computed: {
    ...mapState({
      showLocationPopUp: state => state.app.showLocationPopUp,
    })
  },
  methods: {
    ...mapMutations([ 'setShowCart', 'setShowNav' ]),
    ...mapMutations({
      setShowLocationPopUp: 'app/setShowLocationPopUp',
    }),
    goTo(path) {
      this.setShowLocationPopUp(false)
      this.setShowCart(false)
      this.setShowNav(false)
      this.$router.push(path)
    }
  }
}
