export const state = () => ({
  redeemedOffers: null,
  showLocationPopUp: false
})

export const mutations = {
  setRedeemedOffers (state, obj) {
    state.redeemedOffers = obj
  },
  setShowLocationPopUp (state, bool) {
    state.showLocationPopUp = bool
  }
}