
import Hero from '@/components/storyblok/Hero.vue'

export default {
  name: 'HeroSlider',
  components: { Hero },
  props: {
    blok: Object,
  },
  data() {
    return {
      currentIndex: 0,
      isTransitioning: false
    }
  },
  computed: {
    slides() {
      return this.blok?.Slides || []
    },
    clonedSlides() {
      if (this.slides.length > 0) {
        return [this.slides[this.slides.length - 1], ...this.slides, this.slides[0]]
      }
      return []
    },
    wrapperStyle() {
      return {
        transform: `translateX(-${(this.currentIndex + 1) * 100}%)`,
        transition: this.isTransitioning ? 'transform 0.5s ease-in-out' : 'none'
      }
    }
  },
  methods: {
    nextSlide() {
      if (!this.isTransitioning) {
        this.currentIndex++
        this.isTransitioning = true
      }
    },
    prevSlide() {
      if (!this.isTransitioning) {
        this.currentIndex--
        this.isTransitioning = true
      }
    },
    handleTransitionEnd() {
      if (this.currentIndex === this.slides.length) {
        this.isTransitioning = false
        this.currentIndex = 0
      } else if (this.currentIndex === -1) {
        this.isTransitioning = false
        this.currentIndex = this.slides.length - 1
      } else {
        this.isTransitioning = false
      }
    }
  }
}
