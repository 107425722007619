
import { mapMutations, mapState, mapActions } from 'vuex'
import CustomUpsells from '@/components/common/CustomUpsells.vue'
import CustomLocationSelected from '@/components/common/CustomLocationSelected.vue'
export default {
  name: 'CustomCart',
  components: {
    CustomUpsells,
    CustomLocationSelected,
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    layout: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      showUpsells: false,
    }
  },
  computed: {
    ...mapState([ 'location', 'order', 'cart', 'showCart', 'user', 'token', 'appInfo' ]),
    ...mapState({
      upsells: state => state.checkout.upsells,
      handoffMode: state => state.order.handoffMode,
    }),
    isMobile() {
      return this.$mq == 'sm' || this.$mq == 'md' ? true : false
    },
    locationSelectedAction() {
      return {
        show: true,
        text: 'Change',
        callback: this.setShowLocationPopUp(true),
      }
    },
    appHandoffModes() {
      return this.appInfo.order['handoff_modes']
    },
    locationHandoffModes() {
      if (this.location) {
        return this.$utils.locationHandoffModes(this.location, this.appHandoffModes)
      }

      return []
    },
    appSubHandoffModes() {
      if (this.appHandoffModes && this.appHandoffModes.length > 0) {
        const handoffFound = this.appHandoffModes.find(handoff => handoff.sub_handoff_modes_keys && handoff.sub_handoff_modes_keys.includes(this.handoffMode))
        if (handoffFound && handoffFound.sub_handoff_modes && handoffFound.sub_handoff_modes.length > 0) {
          return handoffFound.sub_handoff_modes
        }
      }
      return []
    },
    locationSubHandoffModes() {
      if (this.location) {
        return this.$utils.locationHandoffModes(this.location, this.appSubHandoffModes)
      }

      return []
    },
    isLocationSelectedDelivery() {
      return this.order?.handoffMode === 'delivery'
    },
    hasUpsells() {
      return this.user && this.upsells?.length > 0 && this.config.cartUpsells
    }
  },
  methods: {
    ...mapMutations({
      setShowLocationPopUp: 'app/setShowLocationPopUp',
    }),
    ...mapActions([ 'hideCart' ]),
    ...mapActions({
      getCartUpsells: 'checkout/getCartUpsells',
    }),
    goToUpsells() {
      this.showUpsells = true
    },
    hideUpsells() {
      this.showUpsells = false
    },
    goToCheckout() {
      this.hide()
      this.$router.push('/order/checkout')
    },
    goToMenu() {
      this.hide()
      const menuPath = this.cart ? `/menu/${this.cart.menu_id}` : '/menu'
      if (this.$nuxt.$route.path !== menuPath) {
        this.$nuxt.$router.push(menuPath)
      }
    },
    hide() {
      document.body.style.overflow = 'auto'
      this.hideUpsells();
      this.hideCart();
    },
  },
  watch: {
    async showCart() {
      this.hideUpsells()
      if (this.user && this.showCart && (this.upsells && this.upsells.length == 0)) {
        await this.getCartUpsells()
      }
    },
  },
}
