import { render, staticRenderFns } from "./AppContainer.vue?vue&type=template&id=2346c458&"
import script from "./AppContainer.vue?vue&type=script&lang=js&"
export * from "./AppContainer.vue?vue&type=script&lang=js&"
import style0 from "./AppContainer.vue?vue&type=style&index=0&id=2346c458&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalsLayoutSiteFooter: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/_globals/layout/SiteFooter.vue').default})
